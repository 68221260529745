import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@toriihq/design-system'
import Router from './components/router'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
import './index.less'
import Cookie from 'cookie'
import { configure } from './shared/redux-fetch'
import config from './config'
import { LOAD_USER_PREFERENCES } from './constants'

configure({
  successSuffix: '_RESPONSE',
  baseUrl: config.proxyApiRequests ? (config.proxyApiRequestsPath || '') : config.apiBaseUrl
})

const defaultHeaders = {
  'x-csrf-token': () => {
    const cookies = Cookie.parse(document.cookie)
    return cookies.crumb || undefined
  }
}

configure({
  defaultHeaders
})

store.dispatch({ type: LOAD_USER_PREFERENCES })

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>, document.getElementById('root'))
